/* eslint-disable react-hooks/exhaustive-deps */
// Chakra imports
import {
    Box,
} from "@chakra-ui/react";
import CompanyTable from "views/admin/default/components/CompanyTable";
import FloorsTable from "views/admin/default/components/FloorsTable";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "contexts/SidebarContext";
import { ListBusiness } from "services/business";
import { ListFloors } from "services/floors";
import { ListBLE } from "services/ble";

export default function Tokens() {
    const { user } = useAuthContext();

    const [list, setList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [amount, setAmount] = useState(1);
    const [searchTxt, setSearchTxt] = useState('');
    const [listFloors, setListFloors] = useState([]);
    const [listBle, setListBle] = useState([]);
    const [listBleInUse, setListBleInUse] = useState([]);
    const [listBleInUseStatik, setListBleInUseStatik] = useState([]);
    const [listBleStock, setListBleStock] = useState([]);
    const [listBleStockStatik, setListBleStockStatik] = useState([]);
    const [frame, setFrame] = useState(1);
    const [infoCompany, setInfoCompany] = useState(null);
    const [infoAndar, setInfoAndar] = useState(null);
    const [draggingOffsetEndTemporary, setDraggingOffsetEndTemporary] = useState(null);
    const [draggingIdTemporary, setDraggingIdTemporary] = useState(null);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            getList();
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [currentPage, searchTxt]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (infoCompany) {
                getListFloors();
            }
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [infoCompany]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (infoAndar && infoCompany) {
                getInfoMap();
            } else {
                setListBle([]);
            }
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [infoAndar, infoCompany]);

    useEffect(() => {
        VerifyRedirectCompany();
    }, []);

    const VerifyRedirectCompany = async () => {
        const infoCompanyStorage = window.sessionStorage.getItem('@indoor_business_id');
        if (infoCompanyStorage) {
            window.sessionStorage.removeItem('@indoor_business_id');
            const response = await ListBusiness(infoCompanyStorage, searchTxt, currentPage, null, user?.token);
            if (response?.result?.list?.length) {
                setInfoCompany(response?.result?.list?.length ? response?.result?.list[0] : null);
                setFrame(2);
            }
        }
    };

    const getList = async () => {
        const response = await ListBusiness(null, searchTxt, currentPage, null, user?.token);
        setList(response?.result?.list || []);
        setTotalPages(response?.result?.qtdPages || 1);
        setAmount(response?.result?.amount || 0);
    };

    const getListFloors = async () => {
        const response = await ListFloors(infoCompany?.id, null, '', 1, null, user?.token);
        setListFloors(response?.result?.list || []);
    };

    const getInfoMap = async () => {
        const response = await ListBLE(infoCompany?.id, infoAndar.id, null, null, '', 1, 10000000, user?.token);
        setListBle(response?.result?.list || []);
        setListBleInUse(response?.result?.list || []);
        setListBleInUseStatik(response?.result?.list || []);
        const responseStock = await ListBLE(null, null, null, true, '', 1, 10000000, user?.token);
        setListBleStock(responseStock?.result?.list || []);
        setListBleStockStatik(responseStock?.result?.list || []);
        setDraggingOffsetEndTemporary(null);
        setDraggingIdTemporary(null);
    };

    useEffect(() => {
      localStorage.removeItem('hypertheme-edit-state');
    }, []);

  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }} fontFamily="Poppins">
        {frame === 1 ? (
            <CompanyTable
                list={list}
                getList={getList}
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                searchTxt={searchTxt}
                setSearchTxt={setSearchTxt}
                amount={amount}
                setFrame={setFrame}
                setInfoCompany={setInfoCompany}
            />
        ) : (
            <FloorsTable
                list={listFloors}
                setList={setListFloors}
                getList={getListFloors}
                getListCompany={getList}
                getInfoMap={getInfoMap}
                setFrame={setFrame}
                infoCompany={infoCompany}
                setInfoCompany={setInfoCompany}
                infoAndar={infoAndar}
                setInfoAndar={setInfoAndar}
                listBle={listBle}
                listBleStock={listBleStock}
                setListBleStock={setListBleStock}
                listBleStockStatik={listBleStockStatik}
                listBleInUse={listBleInUse}
                setListBleInUse={setListBleInUse}
                listBleInUseStatik={listBleInUseStatik}
                draggingOffsetEndTemporary={draggingOffsetEndTemporary}
                setDraggingOffsetEndTemporary={setDraggingOffsetEndTemporary}
                draggingIdTemporary={draggingIdTemporary}
                setDraggingIdTemporary={setDraggingIdTemporary}
            />
        )}
    </Box>
  );
}
