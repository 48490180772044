import { api } from './api';

export async function ListBanners(searchTxt, page, limit, token) {
    try {
        let baseURL = `/banners?limit=${limit || 10}&isAdm=true`;
        if (page) {
            baseURL = `${baseURL}&page=${page}`
        }
        if (searchTxt) {
            baseURL = `${baseURL}&searchTxt=${searchTxt}`
        }
        const response = await api.get(baseURL, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function CreateBanners(idAndar, idBusiness, deadline, file, name, url, token) {
    try {
        const response = await api.post('/banners', { idAndar, idBusiness, deadline, file, name, url }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function EditBanners(idAndar, idBusiness, deadline, file, name, url, idBanner, token) {
    try {
        const response = await api.put(`/banners?idBanner=${idBanner}`, { idAndar, idBusiness, deadline, file, name, url }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function DeleteBanners(idBanner, token) {
    try {
        const response = await api.delete(`/banners?idBanner=${idBanner}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}