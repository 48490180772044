/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    Grid,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import * as Yup from 'yup';
import React, { useState } from "react";

// Custom components
import Card from "components/card/Card";
import { Field, Form, Formik } from "formik";
import { useAuthContext } from "contexts/SidebarContext";
import Pagination from "components/pagination";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { DeleteBusiness } from "services/business";
import { CreateBusiness } from "services/business";
import { EditBusiness } from "services/business";
import { addHours, lightFormat } from "date-fns";
import ConfirmModal from "components/confirmModal";
import { FaPlus } from "react-icons/fa6";
import { EditIcon } from "@chakra-ui/icons";
import { LuTrash } from "react-icons/lu";
import { CgEye } from "react-icons/cg";

export default function CompanyTable(props) {
    const { list, getList, totalPages, currentPage, setCurrentPage, amount, searchTxt, setSearchTxt, setFrame, setInfoCompany } = props;

    const { user } = useAuthContext();
  
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const [isOpen, setIsOpen] = useState(false);
    const [infoRow, setInfoRow] = useState(null);
    const [isOpenDelete, setIsOpenDelete] = useState(false);

    const onClose = () => {
        setIsOpen(false);
        setInfoRow(null);
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('O nome da empresa é obrigatório'),
    });

    const onClickEdit = (item) => {
        setInfoRow(item);
        setIsOpen(true);
    };

    const onClickView = async (item) => {
        setFrame(2);
        setInfoCompany(item);
    };

    const onClickDelete = async (item) => {
        setInfoRow(item);
        setIsOpenDelete(true);
    };

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}
      bg="white"  
    >
        <Flex bg="white" borderRadius="20px" justifyContent="space-between" padding="20px" mb="20px">
            <Stack>
              <Text fontWeight="600" color="black" fontSize="22px">Empresas</Text>
              <Text fontWeight="400" color="#16C098" fontSize="14px">{searchTxt.length ? `${amount} empresas encontradas` : 'Todas as empresas'}</Text>
            </Stack>
            <Flex gap="10px">
              <SearchBar
                  placeholder="Pesquisar empresa"
                  value={searchTxt}
                  setValue={setSearchTxt}
              />
              <Button onClick={() => setIsOpen(true)} w='200px' h='44px' variant="solid" bg="main.700" color="white" gap="10px" fontWeight="400" fontSize="12px">
                <FaPlus size={18} />
                Nova empresa
              </Button>
            </Flex>
        </Flex>

        <Box p={4}>
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th fontSize="14px" fontWeight="500" color="#B5B7C0" fontFamily="Poppins">Nome</Th>
                  <Th fontSize="14px" fontWeight="500" color="#B5B7C0" fontFamily="Poppins">Andares</Th>
                  <Th fontSize="14px" fontWeight="500" color="#B5B7C0" fontFamily="Poppins">BLE's</Th>
                  <Th fontSize="14px" fontWeight="500" color="#B5B7C0" fontFamily="Poppins">Última atualização</Th>
                  <Th fontSize="14px" fontWeight="500" color="#B5B7C0" fontFamily="Poppins">Ações</Th>
                </Tr>
              </Thead>
              <Tbody>
                {list.map((item) => (
                  <Tr key={item.id}>
                    <Td fontSize="14px" fontWeight="500" color="#292D32">{item.name}</Td>
                    <Td fontSize="14px" fontWeight="500" color="#292D32">{item.countAndar || 0}</Td>
                    <Td fontSize="14px" fontWeight="500" color="#292D32">{item.countBle || 0}</Td>
                    <Td fontSize="14px" fontWeight="500" color="#292D32">{item?.andar?.andar_image_updated_at ? lightFormat(addHours(new Date(item.andar.andar_image_updated_at), 3), 'dd/MM/yyyy HH:mm') : '-'}</Td>
                    <Td>
                      <Flex>
                        <Flex p={2} onClick={() => onClickView(item)} cursor="pointer">
                          <CgEye fontSize={20} color="#292D32" />
                        </Flex>
                        <Flex p={2} onClick={() => onClickEdit(item)} cursor="pointer">
                          <EditIcon fontSize={20} color="#5932EA" />
                        </Flex>
                        <Flex p={2} onClick={() => onClickDelete(item)} cursor="pointer">
                          <LuTrash fontSize={20} color="#DF0404" />
                        </Flex>
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>

          {!list?.length ? null : (
            <Flex justify="space-between" align="center" mt={4} mx={6}>
              <Text fontSize="14px" fontWeight="500" color="#B5B7C0">{`Mostrando dados 1 a ${list?.length} de ${amount}`}</Text>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </Flex>
          )}
        </Box>
        <ToastContainer />
        <ConfirmModal
            isOpen={isOpenDelete}
            onClose={() => {
                setIsOpenDelete(false);
                setInfoRow(null);
            }}
            description="Tem certeza que deseja excluir permanentemente essa empresa?"
            title="Confirmação de exclusão"
            onAction={async () => {
                setIsOpenDelete(false);
                await DeleteBusiness(infoRow.id, user?.token);
                setInfoRow(null);
                getList();
            }}
        />
        <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside" size="lg">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader textAlign="center" mt={5} color="#54595E" fontWeight="600" fontSize="20px" fontFamily="Poppins">
                    {infoRow ? 'Editar empresa' : 'Criar empresa'}
                    <Text fontSize='14px' fontWeight='400' color="#54595E99">{`Preencha as informações abaixo para ${infoRow ? 'editar' : 'criar'} uma empresa`}</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody display="flex" flexDirection="column" alignItems="center">
                    <Formik
                        initialValues={{
                            name: infoRow?.name ? infoRow.name : '',
                            latitude: infoRow?.latitude ? Number(infoRow.latitude) : '',
                            longitude: infoRow?.longitude ? Number(infoRow.longitude) : '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, helpers) => {
                            try {
                                const response = !infoRow?.id ? await CreateBusiness(
                                    values.name,
                                    values.latitude,
                                    values.longitude,
                                    user?.token,
                                ) : await EditBusiness(
                                    values.name,
                                    values.latitude,
                                    values.longitude,
                                    infoRow?.id,
                                    user?.token,
                                );
                                if (response?.status === 200 || response?.status === 201) {
                                    toast(response?.message || 'Empresa salva com sucesso!');
                                    helpers.setSubmitting(false);
                                    onClose();
                                    getList();
                                } else {
                                    helpers.setStatus({ success: false });
                                    helpers.setErrors({ submit: response?.message });
                                    helpers.setSubmitting(false);
                                }
                            } catch (err) {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({ submit: err.message });
                                helpers.setSubmitting(false);
                            }
                        }}
                    >
                        {(props) => (
                            <Form style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <Field name='name'>
                                    {({ field, form }) => (
                                        <FormControl mb="20px" isInvalid={form.errors.name && form.touched.name}>
                                            {/* <FormLabel
                                                display='flex'
                                                ms='4px'
                                                fontSize='sm'
                                                fontWeight='500'
                                                color="main.700"
                                                mb='8px'
                                            >
                                                Nome da empresa<Text color="main.700">*</Text>
                                            </FormLabel> */}
                                            <Input
                                                {...field}
                                                variant='auth'
                                                fontSize='sm'
                                                ms={{ base: "0px", md: "0px" }}
                                                placeholder='Nome da empresa'
                                                fontWeight='500'
                                                size='lg'
                                                borderRadius="8px"
                                                fontFamily="Poppins"
                                            />
                                            <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Grid templateColumns='repeat(2, 1fr)' gap='16px' position="block">
                                  <Field name='latitude'>
                                      {({ field, form }) => (
                                          <FormControl mb="20px" isInvalid={form.errors.latitude && form.touched.latitude}>
                                              {/* <FormLabel
                                                  display='flex'
                                                  ms='4px'
                                                  fontSize='sm'
                                                  fontWeight='500'
                                                  color="main.700"
                                                  mb='8px'
                                              >
                                                  Nome da empresa<Text color="main.700">*</Text>
                                              </FormLabel> */}
                                              <Input
                                                  {...field}
                                                  variant='auth'
                                                  fontSize='sm'
                                                  ms={{ base: "0px", md: "0px" }}
                                                  placeholder='Latitude'
                                                  fontWeight='500'
                                                  size='lg'
                                                  borderRadius="8px"
                                                  fontFamily="Poppins"
                                                  type="number"
                                              />
                                              <FormErrorMessage>{form.errors.latitude}</FormErrorMessage>
                                          </FormControl>
                                      )}
                                  </Field>
                                  <Field name='longitude'>
                                      {({ field, form }) => (
                                          <FormControl mb="20px" isInvalid={form.errors.longitude && form.touched.longitude}>
                                              {/* <FormLabel
                                                  display='flex'
                                                  ms='4px'
                                                  fontSize='sm'
                                                  fontWeight='500'
                                                  color="main.700"
                                                  mb='8px'
                                              >
                                                  Nome da empresa<Text color="main.700">*</Text>
                                              </FormLabel> */}
                                              <Input
                                                  {...field}
                                                  variant='auth'
                                                  fontSize='sm'
                                                  ms={{ base: "0px", md: "0px" }}
                                                  placeholder='Longitude'
                                                  fontWeight='500'
                                                  size='lg'
                                                  borderRadius="8px"
                                                  fontFamily="Poppins"
                                                  type="number"
                                              />
                                              <FormErrorMessage>{form.errors.longitude}</FormErrorMessage>
                                          </FormControl>
                                      )}
                                  </Field>
                                </Grid>
                                {props.errors.submit && (
                                    <Text
                                        color={props.status.success ? 'green.500' : 'red.500'}
                                        fontSize='small'
                                        fontWeight='400'
                                        marginTop="20px"
                                    >
                                        {props.errors.submit}
                                    </Text>
                                )}
                                <Button
                                    variant='solid'
                                    bg="main.700"
                                    color="white"
                                    isLoading={props.isSubmitting}
                                    type="submit"
                                    w="full"
                                    borderRadius="8px"
                                    fontWeight="400"
                                    fontFamily="Poppins"
                                >
                                    {infoRow ? 'Editar empresa' : 'Criar empresa'}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>

                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </Card>
  );
}
