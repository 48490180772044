import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import React from "react";

const ConfirmModal = ({ isOpen, onClose, title, description, onAction }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} motionPreset="slideInBottom" isCentered>
            <ModalOverlay />
            <ModalContent fontFamily="Poppins">
                <ModalHeader >{title}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {description}
                </ModalBody>
                <ModalFooter>
                    <Button variant='ghost' onClick={onClose}>Cancelar</Button>
                    <Button bg='red.600' color="white" mr={3} onClick={onAction}>
                        EXCLUIR
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
};

export default ConfirmModal;
