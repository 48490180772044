import React from "react";
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
} from "@chakra-ui/react";
import { RiSearchLine } from "react-icons/ri";
export function SearchBar(props) {
  // Pass the computed styles into the `__css` prop
  const { variant, background, children, placeholder, value, setValue, borderRadius, ...rest } =
    props;
  // Chakra Color Mode
  const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
  return (
    <InputGroup w={{ base: "50%", md: "300px" }} {...rest}>
      <InputLeftElement
        children={
          <IconButton
            bg='inherit'
            borderRadius='inherit'
            _hover='none'
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}
            icon={
              <RiSearchLine color="#7E7E7E" size={22} />
            }></IconButton>
        }
      />
      <Input
        variant='search'
        fontSize='12px'
        bg={background ? background : inputBg}
        fontWeight='500'
        _placeholder={{ color: "gray.400", fontSize: "12px" }}
        borderRadius={borderRadius ? borderRadius : "8px"}
        placeholder={placeholder ? placeholder : "Search..."}
        value={value}
        onChange={(txt) => setValue(txt.target.value)}
      />
    </InputGroup>
  );
}
