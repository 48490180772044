import { api } from './api';

export async function ListBusiness(idBusiness, searchTxt, page, limit, token) {
    try {
        let baseURL = `/business?limit=${limit || 10}`;
        if (page) {
            baseURL = `${baseURL}&page=${page}`
        }
        if (searchTxt) {
            baseURL = `${baseURL}&searchTxt=${searchTxt}`
        }
        if (idBusiness) {
            baseURL = `${baseURL}&idBusiness=${idBusiness}`
        }
        const response = await api.get(baseURL, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}


export async function CreateBusiness(name, latitude, longitude, token) {
    try {
        const response = await api.post('/business', { name, latitude, longitude }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function EditBusiness(name, latitude, longitude, idBusiness, token) {
    try {
        const response = await api.put(`/business?idBusiness=${idBusiness}`, { name, latitude, longitude }, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}

export async function DeleteBusiness(idBusiness, token) {
    try {
        const response = await api.delete(`/business?idBusiness=${idBusiness}`, {
            headers: {
                'Content-Type': 'application/json',
                'x-access-token': token,
            },
        });
        return response?.data;
    } catch (error) {
        const { response } = error;
        return response?.data;
    }
}