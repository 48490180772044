import React from "react";

// Chakra imports
import {
    Box, Flex,
    Text,
    // useColorModeValue
} from "@chakra-ui/react";

// Custom components
// import { HorizonLogo } from "components/icons/Icons";
// import { HSeparator } from "components/separator/Separator";
import { ReactComponent as Logo } from 'assets/img/logoWhite.svg';

export function SidebarBrand() {
  //   Chakra color mode
//   let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align='center' direction='column'>
      <Box my="40px" display="flex" justifyContent="center" alignItems="center" gap="10px">
        <Logo />
        <Text fontSize="24px" color="main.700" fontWeight="600" fontFamily="Poppins">ADENTRU</Text>
      </Box>
      {/* <HSeparator mb='20px' /> */}
    </Flex>
  );
}

export default SidebarBrand;
