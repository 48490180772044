/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Stack,
    Switch,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
} from "@chakra-ui/react";
import * as Yup from 'yup';
import React, { useEffect, useState } from "react";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

// Custom components
import Card from "components/card/Card";
// import Menu from "components/menu/MainMenu";
import { Field, Form, Formik } from "formik";
// import Dropzone from "views/admin/profile/components/Dropzone";
import { addHours, lightFormat } from "date-fns";
import { useAuthContext } from "contexts/SidebarContext";
import Pagination from "components/pagination";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { DeleteBLE } from "services/ble";
import { EnabledBLE } from "services/ble";
import { CreateBLE } from "services/ble";
import { EditBLE } from "services/ble";
import { ListBusiness } from "services/business";
import { ListFloors } from "services/floors";
import { FaPlus } from "react-icons/fa";
// import { CgEye } from "react-icons/cg";
import { EditIcon } from "@chakra-ui/icons";
import { LuTrash } from "react-icons/lu";
import { FiCheckSquare, FiSquare } from "react-icons/fi";

export default function BleTable(props) {
    const { tableData, getList, totalPages, currentPage, setCurrentPage, amount, searchTxt, setSearchTxt, setIdBusiness } = props;

    const { user } = useAuthContext();
  
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const textColor = useColorModeValue("secondaryGray.900", "white");

    const [isOpen, setIsOpen] = useState(false);
    const [infoRow, setInfoRow] = useState(null);
    const [idAndar, setIdAndar] = useState(null);
    const [searchAndar, setSearchAndar] = useState('');
    const [idCompany, setIdCompany] = useState(null);
    const [searchCompany, setSearchCompany] = useState('');
    const [listCompany, setListCompany] = useState([]);
    const [listAndar, setListAndar] = useState([]);

    const onClose = () => {
        setIsOpen(false);
        setInfoRow(null);
        setIdAndar(null);
        setIdCompany(null);
        setSearchAndar('');
        setSearchCompany('');
    };

    const validationSchema = Yup.object().shape({
        simpleId: Yup.string().required('O simpleID é obrigatório'),
        uuid: Yup.string().required('O UUID é obrigatório'),
        macAddress: Yup.string().required('O Mac Address é obrigatório'),
        positionX: Yup.string().notRequired(),
        positionY: Yup.string().notRequired(),
        enabled: Yup.boolean(),
    });

    const onClickEdit = (item) => {
        setIdAndar(item.andar_id || null);
        setIdCompany(item.business_id || null);
        setSearchAndar(item.andar_name || '');
        setSearchCompany(item.business_name || '');
        setInfoRow(item);
        setIsOpen(true);
    };

    const onClickEnable = async (item) => {
        await EnabledBLE(item.id, user?.token);
        getList();
    };

    const onClickDelete = async (item) => {
        await DeleteBLE(item.id, user?.token);
        getList();
    };

    const handleOnSearchAndar = (string) => {
        setSearchAndar(string);
    }

    const handleOnSelectAndar = (item) => {
        setIdAndar(item.id);
    }

    const handleOnSearchCompany = (string, results) => {
        setSearchCompany(string);
    }

    const handleOnSelectCompany = (item, type) => {
        if (type === 'search') {
            setIdBusiness(item.id);
        } else {
            setIdCompany(item.id);
        }
    }

    const handleOnClearCompany = (type) => {
        setIdCompany(null);
        setSearchCompany('');
        setListCompany([]);
        getListCompany();
        if (type === 'search') {
            setIdBusiness(null);
        }
    }

    const handleOnClearAndar = (item) => {
        setIdAndar(null);
        setSearchAndar('');
        setListAndar([]);
        getListFloors();
    }

    const formatResult = (item) => {
        return (
            <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
        )
    }

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            getListCompany();
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [searchCompany]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (idCompany) {
                getListFloors();
            }
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [searchAndar, idCompany]);

    const getListCompany = async () => {
        const response = await ListBusiness(null, searchCompany, 1, null, user?.token);
        setListCompany((response?.result?.list || []).map((v) => ({
            name: v.name,
            id: v.id,
        })));
    };

    const getListFloors = async () => {
        const response = await ListFloors(idCompany, null, searchAndar, 1, null, user?.token);
        setListAndar((response?.result?.list || []).map((v) => ({
            name: v.name,
            id: v.id,
        })));
    };

    const onClickDirection = (item) => {
        window.sessionStorage.setItem('@indoor_business_id', JSON.stringify(item.business_id));
        window.location.reload();
    };

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
        <Flex bg="white" borderRadius="20px" justifyContent="space-between" padding="20px" mb="20px">
            <Stack>
              <Text fontWeight="600" color="black" fontSize="22px">BLE's</Text>
              <Text fontWeight="400" color="#16C098" fontSize="14px">{searchTxt.length ? `${amount} ble's encontrados` : `Todos os BLE's`}</Text>
            </Stack>
            <Flex gap="10px">
              <SearchBar
                  placeholder="Pesquisar BLE"
                  value={searchTxt}
                  setValue={setSearchTxt}
              />
              <Button onClick={() => setIsOpen(true)} w='200px' h='44px' variant="solid" bg="main.700" color="white" gap="10px" fontWeight="400" fontSize="12px">
                <FaPlus size={18} />
                Novo BLE
              </Button>
            </Flex>
        </Flex>

        <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside" size="lg">
            <ModalOverlay />
            <ModalContent fontFamily="Poppins">
                <ModalHeader textAlign="center" mt={5} color="#54595E" fontWeight="600" fontSize="20px">
                    {infoRow ? 'Editar BLE' : 'Criar BLE'}
                    <Text fontSize='14px' fontWeight='400' color="#54595E99">{`Preencha as informações abaixo para ${infoRow ? 'editar' : 'criar'} um BLE`}</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody display="flex" flexDirection="column" alignItems="center">
                    <Formik
                        initialValues={{
                            simpleId: infoRow?.simple_id ? infoRow.simple_id : '',
                            uuid: infoRow?.uuid ? infoRow.uuid : '',
                            macAddress: infoRow?.mac_address ? infoRow.mac_address : '',
                            positionX: infoRow?.position_x ? infoRow.position_x : '',
                            positionY: infoRow?.position_y ? infoRow.position_y : '',
                            enabled: infoRow?.enabled ? infoRow.enabled : false,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, helpers) => {
                            if (!idAndar && infoRow?.id) {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({ submit: 'Selecione um andar' });
                                helpers.setSubmitting(false);
                                return null;
                            }
                            try {
                                const response = !infoRow?.id ? await CreateBLE(
                                    values.simpleId,
                                    values.uuid,
                                    values.macAddress,
                                    user?.token,
                                ) : await EditBLE(
                                    idAndar,
                                    values.simpleId,
                                    values.uuid,
                                    values.macAddress,
                                    values.positionX,
                                    values.positionY,
                                    values.enabled,
                                    infoRow?.id,
                                    user?.token,
                                );
                                if (response?.status === 200 || response?.status === 201) {
                                    toast(response?.message || 'BLE salvo com sucesso!');
                                    helpers.setSubmitting(false);
                                    onClose();
                                    getList();
                                } else {
                                    helpers.setStatus({ success: false });
                                    helpers.setErrors({ submit: response?.message });
                                    helpers.setSubmitting(false);
                                }
                            } catch (err) {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({ submit: err.message });
                                helpers.setSubmitting(false);
                            }
                        }}
                    >
                        {(props) => (
                            <Form style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <Grid templateColumns='repeat(2, 1fr)' gap='16px' position="block">
                                    {!infoRow?.id ? null : (
                                        <>
                                            <Box style={{ maxHeight: 100, minHeight: 100, overflowY: 'auto', padding: '10px' }}>
                                                {/* <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color="main.700"
                                                    mb='8px'
                                                >
                                                    Empresa<Text color="main.700">*</Text>
                                                </FormLabel> */}
                                                <ReactSearchAutocomplete
                                                    items={listCompany}
                                                    onSearch={handleOnSearchCompany}
                                                    onSelect={(item) => handleOnSelectCompany(item, '')}
                                                    onClear={() => handleOnClearCompany('')}
                                                    formatResult={formatResult}
                                                    placeholder="Selec. uma empresa"
                                                    styling={{ fontSize: "14px" }}
                                                    showItemsOnFocus
                                                    autoFocus={false}
                                                    inputSearchString={searchCompany}
                                                />
                                            </Box>
                                            <Box style={{ maxHeight: 100, minHeight: 100, overflowY: 'auto', padding: '10px' }}>
                                                {/* <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color="main.700"
                                                    mb='8px'
                                                >
                                                    Andar<Text color="main.700">*</Text>
                                                </FormLabel> */}
                                                <ReactSearchAutocomplete
                                                    items={listAndar}
                                                    onSearch={handleOnSearchAndar}
                                                    onSelect={handleOnSelectAndar}
                                                    onClear={handleOnClearAndar}
                                                    formatResult={formatResult}
                                                    placeholder="Selec. um andar"
                                                    styling={{ fontSize: "14px" }}
                                                    showItemsOnFocus
                                                    autoFocus={false}
                                                    inputSearchString={searchAndar}
                                                />
                                            </Box>
                                        </>
                                    )}
                                    <Field name='simpleId'>
                                        {({ field, form }) => (
                                            <FormControl mb="20px" isInvalid={form.errors.simpleId && form.touched.simpleId}>
                                                {/* <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color="main.700"
                                                    mb='8px'
                                                >
                                                    SimpleID BLE<Text color="main.700">*</Text>
                                                </FormLabel> */}
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='SimpleID BLE*'
                                                    fontWeight='500'
                                                    size='lg'
                                                    borderRadius="8px"
                                                />
                                                <FormErrorMessage>{form.errors.simpleId}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='uuid'>
                                        {({ field, form }) => (
                                            <FormControl mb="20px" isInvalid={form.errors.uuid && form.touched.uuid}>
                                                {/* <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color="main.700"
                                                    mb='8px'
                                                >
                                                    UUID<Text color="main.700">*</Text>
                                                </FormLabel> */}
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='UUID*'
                                                    fontWeight='500'
                                                    size='lg'
                                                    borderRadius="8px"
                                                />
                                                <FormErrorMessage>{form.errors.uuid}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </Grid>
                                <Field name='macAddress'>
                                    {({ field, form }) => (
                                        <FormControl mb="20px" isInvalid={form.errors.macAddress && form.touched.macAddress}>
                                            {/* <FormLabel
                                                display='flex'
                                                ms='4px'
                                                fontSize='sm'
                                                fontWeight='500'
                                                color="main.700"
                                                mb='8px'
                                            >
                                                Mac address<Text color="main.700">*</Text>
                                            </FormLabel> */}
                                            <Input
                                                {...field}
                                                variant='auth'
                                                fontSize='sm'
                                                ms={{ base: "0px", md: "0px" }}
                                                placeholder='Mac address*'
                                                fontWeight='500'
                                                size='lg'
                                                borderRadius="8px"
                                            />
                                            <FormErrorMessage>{form.errors.macAddress}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                {!infoRow?.id ? null : (
                                    <Grid templateColumns='repeat(2, 1fr)' gap='16px'>
                                        <Field name='positionX'>
                                            {({ field, form }) => (
                                                <FormControl mb="20px" isInvalid={form.errors.positionX && form.touched.positionX}>
                                                    {/* <FormLabel
                                                        display='flex'
                                                        ms='4px'
                                                        fontSize='sm'
                                                        fontWeight='500'
                                                        color="main.700"
                                                        mb='8px'
                                                    >
                                                    Posição X<Text color="main.700">*</Text>
                                                    </FormLabel> */}
                                                    <Input
                                                        {...field}
                                                        variant='auth'
                                                        fontSize='sm'
                                                        ms={{ base: "0px", md: "0px" }}
                                                        placeholder="Posição X*"
                                                        fontWeight='500'
                                                        type="number"
                                                        size='lg'
                                                        borderRadius="8px"
                                                    />
                                                    <FormErrorMessage>{form.errors.positionX}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name='positionY'>
                                            {({ field, form }) => (
                                                <FormControl mb="20px" isInvalid={form.errors.positionY && form.touched.positionY}>
                                                    {/* <FormLabel
                                                        display='flex'
                                                        ms='4px'
                                                        fontSize='sm'
                                                        fontWeight='500'
                                                        color="main.700"
                                                        mb='8px'
                                                    >
                                                        Posição Y<Text color="main.700">*</Text>
                                                    </FormLabel> */}
                                                    <Input
                                                        {...field}
                                                        variant='auth'
                                                        fontSize='sm'
                                                        ms={{ base: "0px", md: "0px" }}
                                                        placeholder="Posição Y*"
                                                        fontWeight='500'
                                                        type="number"
                                                        size='lg'
                                                        borderRadius="8px"
                                                    />
                                                    <FormErrorMessage>{form.errors.positionY}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Grid>
                                )}
                                {!infoRow?.id ? null : (
                                    <Field name='enabled'>
                                        {({ field, form }) => (
                                            <FormControl mb="20px" isInvalid={form.errors.enabled && form.touched.enabled}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Ativar BLE<Text color="main.700">*</Text>
                                                </FormLabel>
                                                <Switch
                                                    {...field}
                                                    size='lg'
                                                    colorScheme='brand'
                                                    isChecked={props.values.enabled}
                                                />
                                                <FormErrorMessage>{form.errors.enabled}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                )}
                                {props.errors.submit && (
                                    <Text
                                        color={props.status.success ? 'green.500' : 'red.500'}
                                        fontSize='small'
                                        fontWeight='400'
                                        marginTop="20px"
                                    >
                                        {props.errors.submit}
                                    </Text>
                                )}
                                <Button
                                    variant='solid'
                                    bg="main.700"
                                    color="white"
                                    isLoading={props.isSubmitting}
                                    type="submit"
                                    w="full"
                                    borderRadius="8px"
                                    fontWeight="500"
                                    fontSize="14px"
                                >
                                    {infoRow ? 'Editar BLE' : 'Criar BLE'}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>

                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal>
        <Box p={4}>
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th fontSize="14px" fontWeight="500" color="#B5B7C0" fontFamily="Poppins">ID</Th>
                  <Th fontSize="14px" fontWeight="500" color="#B5B7C0" fontFamily="Poppins">Empresa</Th>
                  <Th fontSize="14px" fontWeight="500" color="#B5B7C0" fontFamily="Poppins">Data</Th>
                  <Th fontSize="14px" fontWeight="500" color="#B5B7C0" fontFamily="Poppins">Bateria</Th>
                  <Th fontSize="14px" fontWeight="500" color="#B5B7C0" fontFamily="Poppins">Posição X</Th>
                  <Th fontSize="14px" fontWeight="500" color="#B5B7C0" fontFamily="Poppins">Posição Y</Th>
                  <Th fontSize="14px" fontWeight="500" color="#B5B7C0" fontFamily="Poppins">Status</Th>
                  <Th fontSize="14px" fontWeight="500" color="#B5B7C0" fontFamily="Poppins">Ações</Th>
                </Tr>
              </Thead>
              <Tbody>
                {tableData.map((item) => (
                  <Tr key={item.id}>
                    <Td fontSize="14px" fontWeight="500" color="#292D32">{item.id}</Td>
                    <Td fontSize="14px" fontWeight="500" color="#292D32" onClick={() => onClickDirection(item)} textDecorationLine="underline" cursor="pointer">{item.business_name || ''}</Td>
                    <Td fontSize="14px" fontWeight="500" color="#292D32">{item?.created_at ? lightFormat(addHours(new Date(item.created_at), 3), 'dd/MM/yyyy HH:mm') : '-'}</Td>
                    <Td fontSize="14px" fontWeight="500" color="#292D32">{item.energy || ''}</Td>
                    <Td fontSize="14px" fontWeight="500" color="#292D32">{item.position_x || ''}</Td>
                    <Td fontSize="14px" fontWeight="500" color="#292D32">{item.position_y || ''}</Td>
                    <Td>
                      <Flex
                        p="4px 12px 4px 12px"
                        borderRadius="4px"
                        border="1px"
                        borderColor={item.enabled ? "#00B087" : "#DF0404"}
                        bg={item.enabled ? "#16C09861" : "#FFC5C5"}
                        alignItems="center"
                        justifyContent="center"
                        minW="80px"
                      >
                        <Text color={item.enabled ? '#008767' : '#DF0404'}>{item.enabled ? 'Ativo' : 'Inativo'}</Text>
                      </Flex>
                    </Td>
                    <Td>
                      <Flex>
                        <Flex p={2} onClick={() => onClickEnable(item)} cursor="pointer">
                          {item.enabled ? <FiCheckSquare fontSize={20} color="#008767" /> : <FiSquare fontSize={20} color="#DF0404" />}
                        </Flex>
                        <Flex p={2} onClick={() => onClickEdit(item)} cursor="pointer">
                          <EditIcon fontSize={20} color="#5932EA" />
                        </Flex>
                        <Flex p={2} onClick={() => onClickDelete(item)} cursor="pointer">
                          <LuTrash fontSize={20} color="#DF0404" />
                        </Flex>
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>

          {!tableData?.length ? null : (
            <Flex justify="space-between" align="center" mt={4} mx={6}>
              <Text fontSize="14px" fontWeight="500" color="#B5B7C0">{`Mostrando dados 1 a ${tableData?.length} de ${amount}`}</Text>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </Flex>
          )}
        </Box>
        <ToastContainer />
    </Card>
  );
}
