/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import * as Yup from 'yup';
import { ReactComponent as Gallery } from 'assets/img/gallery.svg';
import React, { useEffect, useState } from "react";
import { v4 } from "uuid";

// Custom components
import Card from "components/card/Card";
import { Field, Form, Formik } from "formik";
// import Dropzone from "views/admin/profile/components/Dropzone";
import { addHours, lightFormat } from "date-fns";
import { useAuthContext } from "contexts/SidebarContext";
import Pagination from "components/pagination";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { DeleteBanners } from "services/banners";
import { CreateBanners } from "services/banners";
import { EditBanners } from "services/banners";
import Dropzone from "components/dropzone";
import { getNameWithExtensionFromUrl } from "utils/masks";
import { FaPlus } from "react-icons/fa";
import { EditIcon } from "@chakra-ui/icons";
import { LuTrash } from "react-icons/lu";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { ListBusiness } from "services/business";
import { ListFloors } from "services/floors";

export default function BannersTable(props) {
  const { tableData, getList, totalPages, currentPage, setCurrentPage, amount, searchTxt, setSearchTxt } = props;

  const { user } = useAuthContext();

  const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [infoRow, setInfoRow] = useState(null);
  const [idFile, setIdFile] = useState('1');
  const [idAndar, setIdAndar] = useState(null);
  const [searchAndar, setSearchAndar] = useState('');
  const [idCompany, setIdCompany] = useState(null);
  const [searchCompany, setSearchCompany] = useState('');
  const [listCompany, setListCompany] = useState([]);
  const [listAndar, setListAndar] = useState([]);

  const onClose = () => {
      setIsOpen(false);
      setInfoRow(null);
  };

  const validationSchema = Yup.object().shape({
      file: Yup.array().min(1, 'Adicione uma imagem').required('Adicione a imagem do banner.'),
      name: Yup.string().required('O nome é obrigatório'),
  });

  const handleOnSearchAndar = (string) => {
      setSearchAndar(string);
  }

  const handleOnSelectAndar = (item) => {
      setIdAndar(item.id);
  }

  const handleOnSearchCompany = (string, results) => {
      setSearchCompany(string);
  }

  const handleOnSelectCompany = (item, type) => {
    setIdCompany(item.id);
  }

  const handleOnClearCompany = (type) => {
      setIdCompany(null);
      setSearchCompany('');
      setListCompany([]);
      getListCompany();
  }

  const handleOnClearAndar = (item) => {
      setIdAndar(null);
      setSearchAndar('');
      setListAndar([]);
      getListFloors();
  }

  const formatResult = (item) => {
    return (
      <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
    )
  }

  useEffect(() => {
      const timeOutId = setTimeout(() => {
          getListCompany();
      }, 500);
      return () => clearTimeout(timeOutId);
  }, [searchCompany]);

  useEffect(() => {
      const timeOutId = setTimeout(() => {
          if (idCompany) {
              getListFloors();
          }
      }, 500);
      return () => clearTimeout(timeOutId);
  }, [searchAndar, idCompany]);

  const getListCompany = async () => {
      const response = await ListBusiness(null, searchCompany, 1, null, user?.token);
      setListCompany((response?.result?.list || []).map((v) => ({
          name: v.name,
          id: v.id,
      })));
  };

  const getListFloors = async () => {
      const response = await ListFloors(idCompany, null, searchAndar, 1, null, user?.token);
      setListAndar((response?.result?.list || []).map((v) => ({
          name: v.name,
          id: v.id,
      })));
  };

  const onClickEdit = (item) => {
      setIdAndar(item.andar_id || null);
      setIdCompany(item.business_id || null);
      setSearchAndar(item.andar_name || '');
      setSearchCompany(item.business_name || '');
      setInfoRow(item);
      setIsOpen(true);
  };

  const onClickDelete = async (item) => {
      await DeleteBanners(item.id, user?.token);
      getList();
  };

return (
  <Card
    direction='column'
    w='100%'
    px='0px'
    overflowX={{ sm: "scroll", lg: "hidden" }}
  >
      <Flex bg="white" borderRadius="20px" justifyContent="space-between" padding="20px" mb="20px">
          <Stack>
            <Text fontWeight="600" color="black" fontSize="22px">Banners</Text>
            <Text fontWeight="400" color="#16C098" fontSize="14px">{searchTxt.length ? `${amount} banners encontrados` : `Todos os banners`}</Text>
          </Stack>
          <Flex gap="10px">
            <SearchBar
                placeholder="Pesquisar banner"
                value={searchTxt}
                setValue={setSearchTxt}
            />
            <Button onClick={() => setIsOpen(true)} w='200px' h='44px' variant="solid" bg="main.700" color="white" gap="10px" fontWeight="400" fontSize="12px">
              <FaPlus size={18} />
              Novo banner
            </Button>
          </Flex>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside" size="lg">
          <ModalOverlay />
          <ModalContent fontFamily="Poppins">
              <ModalHeader textAlign="center" mt={5} color="#54595E" fontWeight="600" fontSize="20px">
                  {infoRow ? 'Editar banner' : 'Criar banner'}
                  <Text fontSize='14px' fontWeight='400' color="#54595E99">{`Preencha as informações abaixo para ${infoRow ? 'editar' : 'criar'} um banner`}</Text>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody display="flex" flexDirection="column" alignItems="center">
                  <Formik
                      initialValues={{
                          file: infoRow?.file ? [infoRow.file] : [],
                          name: infoRow?.name ? infoRow.name : '',
                          url: infoRow?.url ? infoRow.url : '',
                          deadline: infoRow?.deadline ? infoRow.deadline.substring(0, 16) : '',
                      }}
                      validationSchema={validationSchema}
                      onSubmit={async (values, helpers) => {
                          try {
                              if (!idAndar && infoRow?.id) {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({ submit: 'Selecione um andar' });
                                helpers.setSubmitting(false);
                                return null;
                              }
                              const response = !infoRow?.id ? await CreateBanners(
                                  idAndar,
                                  idCompany,
                                  values.deadline,
                                  values.file[0],
                                  values.name,
                                  values.url,
                                  user?.token,
                              ) : await EditBanners(
                                  idAndar,
                                  idCompany,
                                  values.deadline,
                                  values.file[0],
                                  values.name,
                                  values.url,
                                  infoRow?.id,
                                  user?.token,
                              );
                              if (response?.status === 200 || response?.status === 201) {
                                  toast(response?.message || 'Banner salvo com sucesso!');
                                  helpers.setSubmitting(false);
                                  onClose();
                                  getList();
                              } else {
                                  helpers.setStatus({ success: false });
                                  helpers.setErrors({ submit: response?.message });
                                  helpers.setSubmitting(false);
                              }
                          } catch (err) {
                              helpers.setStatus({ success: false });
                              helpers.setErrors({ submit: err.message });
                              helpers.setSubmitting(false);
                          }
                      }}
                  >
                      {(props) => (
                          <Form style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <Box style={{ maxHeight: 100, minHeight: 100, overflowY: 'auto', padding: '10px' }}>
                                    {/* <FormLabel
                                        display='flex'
                                        ms='4px'
                                        fontSize='sm'
                                        fontWeight='500'
                                        color="main.700"
                                        mb='8px'
                                    >
                                        Empresa<Text color="main.700">*</Text>
                                    </FormLabel> */}
                                    <ReactSearchAutocomplete
                                        items={listCompany}
                                        onSearch={handleOnSearchCompany}
                                        onSelect={(item) => handleOnSelectCompany(item, '')}
                                        onClear={() => handleOnClearCompany('')}
                                        formatResult={formatResult}
                                        placeholder="Selec. uma empresa"
                                        styling={{ fontSize: "14px" }}
                                        showItemsOnFocus
                                        autoFocus={false}
                                        inputSearchString={searchCompany}
                                    />
                                </Box>
                                <Box style={{ maxHeight: 100, minHeight: 100, overflowY: 'auto', padding: '10px' }}>
                                    {/* <FormLabel
                                        display='flex'
                                        ms='4px'
                                        fontSize='sm'
                                        fontWeight='500'
                                        color="main.700"
                                        mb='8px'
                                    >
                                        Andar<Text color="main.700">*</Text>
                                    </FormLabel> */}
                                    <ReactSearchAutocomplete
                                        items={listAndar}
                                        onSearch={handleOnSearchAndar}
                                        onSelect={handleOnSelectAndar}
                                        onClear={handleOnClearAndar}
                                        formatResult={formatResult}
                                        placeholder="Selec. um andar"
                                        styling={{ fontSize: "14px" }}
                                        showItemsOnFocus
                                        autoFocus={false}
                                        inputSearchString={searchAndar}
                                    />
                                </Box>
                                  <Grid templateColumns='repeat(2, 1fr)' gap='16px' position="block">
                                    <Field name='name'>
                                      {({ field, form }) => (
                                          <FormControl mb="20px" isInvalid={form.errors.name && form.touched.name}>
                                              {/* <FormLabel
                                                  display='flex'
                                                  ms='4px'
                                                  fontSize='sm'
                                                  fontWeight='500'
                                                  color="main.700"
                                                  mb='8px'
                                              >
                                                  Nome<Text color="main.700">*</Text>
                                              </FormLabel> */}
                                              <Input
                                                  {...field}
                                                  variant='auth'
                                                  fontSize='sm'
                                                  ms={{ base: "0px", md: "0px" }}
                                                  placeholder='Nome do banner'
                                                  fontWeight='500'
                                                  size='lg'
                                                  borderRadius="8px"
                                              />
                                              <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                          </FormControl>
                                      )}
                                  </Field>
                                  <Field name='deadline'>
                                      {({ field, form }) => (
                                          <FormControl mb="20px" isInvalid={form.errors.deadline && form.touched.deadline}>
                                              {/* <FormLabel
                                                  display='flex'
                                                  ms='4px'
                                                  fontSize='sm'
                                                  fontWeight='500'
                                                  color="main.700"
                                                  mb='8px'
                                              >
                                                  Nome<Text color="main.700">*</Text>
                                              </FormLabel> */}
                                              <Input
                                                  {...field}
                                                  variant='auth'
                                                  fontSize='sm'
                                                  ms={{ base: "0px", md: "0px" }}
                                                  placeholder='Tempo limite'
                                                  fontWeight='500'
                                                  type="datetime-local"
                                                  size='lg'
                                                  borderRadius="8px"
                                              />
                                              <FormErrorMessage>{form.errors.deadline}</FormErrorMessage>
                                          </FormControl>
                                      )}
                                  </Field>
                                </Grid>
                                <Field name='url'>
                                  {({ field, form }) => (
                                      <FormControl mb="20px" isInvalid={form.errors.url && form.touched.url}>
                                          {/* <FormLabel
                                              display='flex'
                                              ms='4px'
                                              fontSize='sm'
                                              fontWeight='500'
                                              color="main.700"
                                              mb='8px'
                                          >
                                              Nome<Text color="main.700">*</Text>
                                          </FormLabel> */}
                                          <Input
                                              {...field}
                                              variant='auth'
                                              fontSize='sm'
                                              ms={{ base: "0px", md: "0px" }}
                                              placeholder='URL de redirecionamento'
                                              fontWeight='500'
                                              size='lg'
                                              borderRadius="8px"
                                          />
                                          <FormErrorMessage>{form.errors.url}</FormErrorMessage>
                                      </FormControl>
                                  )}
                              </Field>
                                <Field name='file'>
                                    {({ form }) => (
                                        <FormControl isInvalid={form.errors.file && form.touched.file}>
                                            <Flex display="flex" flexDirection="column" maxW="100%">
                                                <Dropzone
                                                    minH="158px"
                                                    w="100%"
                                                    borderRadius="10px"
                                                    onImageSelect={(file) => {
                                                        const uuid = v4();
                                                        setIdFile(uuid);
                                                        form.setFieldValue('file', file);
                                                    }}
                                                    accept="image/png, image/jpeg, image/jpg"
                                                    content={
                                                        <Box display="flex" flexDirection="column" gap="10px" alignItems="center" justifyContent="center">
                                                            {form?.values?.file?.length > 0 ? (
                                                                <Text color='blue.500' fontSize='xs' fontWeight='400'>{getNameWithExtensionFromUrl(String(form?.values?.file[0]), idFile)}</Text>
                                                            ) : (
                                                                <>
                                                                    <Gallery />
                                                                    <Text color='#ACACAC' fontSize='12px' fontWeight='400'>Selecione ou arraste uma imagem.</Text>
                                                                    <Text color='#ACACAC' fontSize='8px' fontWeight='400'>Formatos aceitos: PNG, JPEG, JPG</Text>
                                                                </>
                                                            )}
                                                        </Box>
                                                    }
                                                />
                                            </Flex>
                                            <FormErrorMessage>{form.errors.file}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                              {props.errors.submit && (
                                  <Text
                                      color={props.status.success ? 'green.500' : 'red.500'}
                                      fontSize='small'
                                      fontWeight='400'
                                      marginTop="20px"
                                  >
                                      {props.errors.submit}
                                  </Text>
                              )}
                              <Button
                                  variant='solid'
                                  bg="main.700"
                                  color="white"
                                  isLoading={props.isSubmitting}
                                  type="submit"
                                  w="full"
                                  borderRadius="8px"
                                  mt="20px"
                                  fontWeight="500"
                                  fontSize="14px"
                              >
                                  {infoRow ? 'Editar banner' : 'Criar banner'}
                              </Button>
                          </Form>
                      )}
                  </Formik>
              </ModalBody>

              <ModalFooter>
              </ModalFooter>
          </ModalContent>
      </Modal>

      <Box p={4}>
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th fontSize="12px" fontWeight="500" color="#B5B7C0" fontFamily="Poppins">Imagem</Th>
                <Th fontSize="12px" fontWeight="500" color="#B5B7C0" fontFamily="Poppins">Nome</Th>
                {/* <Th fontSize="12px" fontWeight="500" color="#B5B7C0" fontFamily="Poppins">Empresa</Th>
                <Th fontSize="12px" fontWeight="500" color="#B5B7C0" fontFamily="Poppins">Andar</Th> */}
                <Th fontSize="12px" fontWeight="500" color="#B5B7C0" fontFamily="Poppins">Data</Th>
                <Th fontSize="12px" fontWeight="500" color="#B5B7C0" fontFamily="Poppins">Ações</Th>
              </Tr>
            </Thead>
            <Tbody>
              {tableData.map((item) => (
                <Tr key={item.id}>
                  <Td>
                    <img style={{ width: 60, height: 40, borderRadius: 8, objectFit: 'cover' }} src={item.file} alt="banner" />
                  </Td>
                  <Td fontSize="14px" fontWeight="500" color="#292D32">{item.name}</Td>
                  {/* <Td fontSize="14px" fontWeight="500" color="#292D32">{item.company_name || '-'}</Td>
                  <Td fontSize="14px" fontWeight="500" color="#292D32">{item.floor_name || '-'}</Td> */}
                  <Td fontSize="14px" fontWeight="500" color="#292D32">{item?.created_at ? lightFormat(addHours(new Date(item.created_at), 3), 'dd/MM/yyyy HH:mm') : '-'}</Td>
                  <Td>
                    <Flex>
                      <Flex p={2} onClick={() => onClickEdit(item)} cursor="pointer">
                        <EditIcon fontSize={20} color="#5932EA" />
                      </Flex>
                      <Flex p={2} onClick={() => onClickDelete(item)} cursor="pointer">
                        <LuTrash fontSize={20} color="#DF0404" />
                      </Flex>
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>

        {!tableData?.length ? null : (
          <Flex justify="space-between" align="center" mt={4} mx={6}>
            <Text fontSize="14px" fontWeight="500" color="#B5B7C0">{`Mostrando dados 1 a ${tableData?.length} de ${amount}`}</Text>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />
          </Flex>
        )}
      </Box>
      <ToastContainer />
  </Card>
);
}
