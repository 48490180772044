/* eslint-disable react-hooks/exhaustive-deps */
// Chakra imports
import {
  Box
  // SimpleGrid,
} from "@chakra-ui/react";
import BannersTable from "views/admin/banners/components/BannersTable";
import React, { useEffect, useState } from "react";
import { useAuthContext } from "contexts/SidebarContext";
import { ListBanners } from "services/banners";

export default function Banners() {
  const { user } = useAuthContext();

  const [list, setList] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [amount, setAmount] = useState(1);
  const [searchTxt, setSearchTxt] = useState('');
  const [idBusiness, setIdBusiness] = useState(null);

  useEffect(() => {
      const timeOutId = setTimeout(() => {
          getList();
      }, 500);
      return () => clearTimeout(timeOutId);
  }, [currentPage, searchTxt, idBusiness]);

  const getList = async () => {
      const response = await ListBanners(searchTxt, currentPage, null, user?.token);
      setList(response?.result?.list || []);
      setAmount(response?.result?.amount || 1);
      setTotalPages(response?.result?.qtdPages || 1);
  };

// Chakra Color Mode
return (
  <Box pt={{ base: "130px", md: "80px", xl: "80px" }} fontFamily="Poppins">
      <BannersTable
          tableData={list}
          getList={getList}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          amount={amount}
          searchTxt={searchTxt}
          setSearchTxt={setSearchTxt}
          setIdBusiness={setIdBusiness}
      />
  </Box>
);
}
