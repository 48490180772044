/* eslint-disable react-hooks/exhaustive-deps */
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    Icon,
    IconButton,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Popover,
    PopoverBody,
    PopoverCloseButton,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Select,
    Stack,
    Switch,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import * as Yup from 'yup';
import React, { useEffect, useState } from "react";
import { ReactComponent as Gallery } from 'assets/img/gallery.svg';
import { ReactComponent as IconsMap } from 'assets/img/iconsMap.svg';
import { ReactComponent as IconsClear } from 'assets/img/iconsClear.svg';

// Custom components
import Card from "components/card/Card";
import { Field, Form, Formik } from "formik";
// import Dropzone from "views/admin/profile/components/Dropzone";
import { useAuthContext } from "contexts/SidebarContext";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { CreateFloors } from "services/floors";
import { EditFloors } from "services/floors";
import { DeleteFloors } from "services/floors";
import { getNameWithExtensionFromUrl } from "utils/masks";
import Dropzone from "components/dropzone";
import { FaBluetooth } from "react-icons/fa";
import { EditBLE } from "services/ble";
import { ListFloors } from "services/floors";
import { GrAdd, GrSubtract } from "react-icons/gr";
import { CreateBLE } from "services/ble";
import { DeleteBLE } from "services/ble";
import { v4 } from "uuid";
import { IoIosArrowBack, IoIosArrowForward, IoMdCloseCircleOutline } from "react-icons/io";
import { TbPlayerPlay, TbPlayerStop } from "react-icons/tb";
import { IoBluetooth } from "react-icons/io5";
import { EditIcon } from "@chakra-ui/icons";
import { LuTrash } from "react-icons/lu";

export default function FloorsTable(props) {
    // eslint-disable-next-line no-unused-vars
    const { list, setList, getList, getListCompany, getInfoMap, setFrame, infoCompany, setInfoCompany, infoAndar, setInfoAndar, listBle, listBleStock, setListBleStock, listBleStockStatik, listBleInUse, setListBleInUse, listBleInUseStatik, draggingOffsetEndTemporary, setDraggingOffsetEndTemporary, draggingIdTemporary, setDraggingIdTemporary } = props;

    const { user } = useAuthContext();

    const textColor = useColorModeValue("secondaryGray.900", "white");

    const [isOpen, setIsOpen] = useState(false);
    const [infoRow, setInfoRow] = useState(null);
    const [idBleInUseSelected, setIdBleInUseSelected] = useState(null);
    const [draggingId, setDraggingId] = useState(null);
    const [draggingOffset, setDraggingOffset] = useState({ x: 0, y: 0 });
    const [draggingOffsetEnd, setDraggingOffsetEnd] = useState(null);
    const [draggingItem, setDraggingItem] = useState(null);
    const [isShowAutocomplete, setIsShowAutocomplete] = useState(true);
    const [isOpenBLE, setIsOpenBLE] = useState(false);
    const [isOpenBleInUse, setIsOpenBleInUse] = useState(false);
    const [searchTxtBle, setSearchTxtBle] = useState('');
    const [searchTxtBleInUse, setSearchTxtBleInUse] = useState('');
    const [zoom, setZoom] = useState(1);
    const [infoRowBle, setInfoRowBle] = useState(null);
    const [isOpenModalBle, setIsOpenModalBle] = useState(false);
    const [idImage, setIdImage] = useState(v4());
    const [isDrawing, setIsDrawing] = useState(false);
    const [points, setPoints] = useState([]);

    console.log('listBleStock', listBleStock);
    const updatePointsFloor = async () => {
        try {
            const response = await EditFloors(
                points,
                infoAndar.name,
                infoAndar.andar_image,
                infoAndar.period_start,
                infoAndar.period_end,
                infoCompany?.id,
                infoAndar?.id,
                user?.token,
            );
            if (response?.status === 200 || response?.status === 201) {
                setIsDrawing(false);
                toast(response?.message || 'Andar salvo com sucesso!');
            } else {
                alert('Ocorreu algum erro.');
            }
        } catch (err) {
            alert('Ocorreu algum erro.');
        }
    };

    const handleBoxClick = (e) => {
        if (!isDrawing) return;

        const rect = e.target.getBoundingClientRect();
        const x = (e.clientX - rect.left) / zoom;
        const y = (e.clientY - rect.top) / zoom;
    
        setPoints([...points, { x, y }]);
    };

    const toggleDrawing = () => {
        setIsDrawing(!isDrawing);
    };

    const cleanRoute = () => {
        setPoints([]);
    };

    const handleZoomIn = () => {
        setZoom(zoom + 0.1);
    };

    const handleZoomOut = () => {
        if (zoom <= 0.2) {
            return null;
        }
        return setZoom(zoom - 0.1);
    };

    const onClose = () => {
        setIsOpen(false);
        setInfoRow(null);
    };

    const onCloseBle = () => {
        setIsOpenModalBle(false);
        setInfoRowBle(null);
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('O nome do andar é obrigatório'),
        image: Yup.array().min(1, 'Adicione uma imagem').required('Adicione a imagem do andar.'),
        periodStart: Yup.string().required('A Data inicial é obrigatória'),
        periodEnd: Yup.string().required('A Data final é obrigatória'),
    });

    const validationSchemaBle = Yup.object().shape({
        simpleId: Yup.string().required('O simpleID é obrigatório'),
        uuid: Yup.string().required('O UUID é obrigatório'),
        macAddress: Yup.string().required('O Mac Address é obrigatório'),
        positionX: Yup.string().notRequired(),
        positionY: Yup.string().notRequired(),
        enabled: Yup.boolean(),
    });

    const onClickEditBle = (item) => {
        setInfoRowBle(item);
        setIsOpenModalBle(true);
    };

    const onClickEdit = (item) => {
        setInfoRow(item);
        setIsOpen(true);
    };

    const onClickDelete = async (item) => {
        setIsShowAutocomplete(false);
        await DeleteFloors(item.id, user?.token);
        setPoints([]);
        setInfoAndar(null);
        setIsShowAutocomplete(true);
        toast('Andar deletado com sucesso');
        getList();
    };

    const handleDragStart = (id, item, e) => {
        setDraggingOffsetEndTemporary(null);
        setDraggingOffsetEnd(null);
        setDraggingId(id);
        setDraggingIdTemporary(id);
        setDraggingItem(item);
        const boundingRect = e.target.getBoundingClientRect();
        const offsetX = (e.clientX - boundingRect.left) / zoom;
        const offsetY = (e.clientY - boundingRect.top) / zoom;
        setDraggingOffset({ x: offsetX, y: offsetY });
    };

    const handleDragEnd = () => {
        setDraggingId(null);
    };

    const handleDrag = (e) => {
        if (draggingId !== null) {
            const boundingRect = e.currentTarget.getBoundingClientRect();
            const minX = 0;
            const minY = 0;
            const maxX = (boundingRect.width - 20) / zoom;
            const maxY = (boundingRect.height - 20) / zoom;
            let newX = (e.clientX - boundingRect.left - draggingOffset.x) / zoom;
            let newY = (e.clientY - boundingRect.top - draggingOffset.y) / zoom;

            newX = Math.max(minX, Math.min(newX, maxX));
            newY = Math.max(minY, Math.min(newY, maxY));

            setDraggingOffsetEnd({ x: newX, y: newY });
            setDraggingOffsetEndTemporary({ x: newX, y: newY });
        }
    };

    const UpdateBLE = async (item, position) => {
        try {
            const response = await EditBLE(
                infoAndar?.id,
                item.simple_id,
                item.uuid,
                item.mac_address,
                position.x,
                position.y,
                item.enabled,
                item?.id,
                user?.token,
            );
            if (response?.status === 200 || response?.status === 201) {
                toast(response?.message || 'BLE salvo com sucesso!');
                getInfoMap();
            } else {
                alert('Ocorreu algum erro.');
            }
        } catch (err) {
            alert('Ocorreu algum erro.');
        }
    };

    const onClickDeleteBLE = async (item) => {
        await DeleteBLE(item.id, user?.token);
        onCloseBle();
        getInfoMap();
    };

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (draggingOffsetEnd) {
                UpdateBLE(draggingItem, draggingOffsetEnd);
            }
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [draggingOffsetEnd]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            const applySearch = listBleStockStatik.filter((v) => v.simple_id.toUpperCase().includes(searchTxtBle.toUpperCase()));
            setListBleStock(applySearch);
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [searchTxtBle]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            const applySearch = listBleInUseStatik.filter((v) => v.simple_id.toUpperCase().includes(searchTxtBleInUse.toUpperCase()));
            setListBleInUse(applySearch);
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [searchTxtBleInUse]);

  return (
    <Card
      direction='column'
      minW="1000px"
      w='100%'
      p="0px"
      minH="600px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
        <Flex alignItems="center">
            <Flex
                onClick={() => {
                    setPoints([]);
                    setInfoAndar(null);
                    setInfoCompany(null);
                    setFrame(1);
                    setList([]);
                    getListCompany();
                }}
                w='100px'
                minH='77px'
                bg="transparent"
                color="#9197B3"
                justifyContent="center"
                px="15px"
                alignItems="center"
                cursor="pointer"
                fontSize="14px"
                fontWeight="500"
                borderRightWidth={2}
                borderColor="#F5F6FA"
                gap="10px"
            >
              <IoIosArrowBack color="#9197B3" size={16} />
              Voltar
            </Flex>
            <Flex
                onClick={() => {
                  setPoints([]);
                  setInfoAndar(null);
                }}
                minH='77px'
                bg="transparent"
                color={infoAndar ? "#9197B3" : "#292D32"}
                justifyContent="space-between"
                px="15px"
                alignItems="center"
                cursor="pointer"
                fontSize="14px"
                fontWeight="500"
                gap="10px"
            >
              {infoCompany?.name}
              {infoAndar && <IoIosArrowForward color="#9197B3" size={16} />}
              {infoAndar && (
                <Text color="#292D32">{infoAndar?.name}</Text>
              )}
            </Flex>
        </Flex>
        <Flex bg="#F5F6FA" gap='16px' justifyContent="space-between" flex={1} pt={1}>
            <Flex flex={3} direction="column" alignItems="center" justifyContent="center" overflow="auto" position="relative" borderBottomLeftRadius="30px">
              {!infoAndar ? null : (
                <>
                  <Flex position="absolute" top="20px" left="20px" gap="10px" zIndex={1000} flexWrap="wrap">
                    {listBle?.map((e) => (
                      <Button
                        onClick={() => onClickEditBle(e)}
                        bg="#55DB30"
                        borderRadius="8px"
                        fontWeight="500"
                        color="white"
                        gap="10px"
                        justifyContent="flex-start"
                        minH="26px"
                        maxH="26px"
                        fontSize="10px"
                        key={e.id}
                      >
                        {`BLE ${e.id}`}
                        <IoMdCloseCircleOutline color="white" />
                      </Button>
                    ))}
                  </Flex>
                  <Flex position="absolute" bottom="20px" right="20px" gap="10px" zIndex={1000}>
                    <IconButton shadow="sm" borderRadius="8px" onClick={() => onClickEdit(infoAndar)}>
                      <EditIcon fontSize={20} color="#5932EA" />
                    </IconButton>
                    <IconButton shadow="sm" borderRadius="8px" onClick={() => onClickDelete(infoAndar)}>
                      <LuTrash fontSize={20} color="#DF0404" />
                    </IconButton>
                  </Flex>
                  <Flex position="absolute" bottom="20px" left="20px" gap="1px" zIndex={1000}>
                    <IconButton shadow="sm" borderRadius="8px 0px 0px 8px" onClick={() => handleZoomOut()}>
                      <GrSubtract size={20} color="#9FA4BC" />
                    </IconButton>
                    <IconButton shadow="sm" borderRadius="0px 8px 8px 0px" onClick={() => handleZoomIn()}>
                      <GrAdd size={20} color="#9FA4BC" />
                    </IconButton>
                    {/* <Button
                        onClick={() => handleZoomOut()}
                        w='40px'
                        h='40px' 
                        variant='solid'
                        bg="main.700"
                        color="white"
                        borderRadius="20px"
                    >
                        <Icon as={GrSubtractCircle} width='20px' height='20px' color='white' />
                    </Button> */}
                    {/* {`Escala: ${zoom.toFixed(1)}`} */}
                    {/* <Button
                        onClick={() => handleZoomIn()}
                        w='40px'
                        h='40px' 
                        variant='solid'
                        bg="main.700"
                        color="white"
                        borderRadius="20px"
                    >
                        <Icon as={CgAdd} width='20px' height='20px' color='white' />
                    </Button> */}
                  </Flex>
                </>
              )}
              {!infoAndar ? (
                <>
                  <IconsMap />
                  <Text>O mapa do andar aparecerá aqui</Text>
                </>
              ) : (
                <Box overflow="auto" maxWidth="90%" minWidth="90%" alignSelf="center">
                  <Box
                      position="relative"
                      mb="28px"
                      style={{
                          backgroundImage: `url(${infoAndar.andar_image})`,
                          borderRadius: '16px',
                          minHeight: '1000px',
                          maxHeight: '1000px',
                          minWidth: '1000px',
                          maxWidth: '1000px',
                          aspectRatio: 1,
                          backgroundSize: 'contain',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                          position: 'relative',
                          transform: `scale(${zoom})`,
                          transformOrigin: 'top left',
                      }}
                      onMouseMove={handleDrag}
                      onMouseUp={handleDragEnd}
                      onClick={handleBoxClick}
                  >
                      {listBle.map((v) => (
                          <div
                              style={{
                                  backgroundColor: idBleInUseSelected === v.id ? '#EE5D50' : 'transparent',
                                  position: 'absolute',
                                  padding: '5px 2px 0px 2px',
                                  borderRadius: 5,
                                  cursor: 'pointer',
                                  top: `${draggingOffsetEndTemporary?.y && draggingIdTemporary === v.id ? draggingOffsetEndTemporary?.y : v.position_y}px`,
                                  left: `${draggingOffsetEndTemporary?.x && draggingIdTemporary === v.id ? draggingOffsetEndTemporary?.x : v.position_x}px`,
                                  pointerEvents: isDrawing ? 'none' : 'auto',
                              }}
                              key={v.id}
                              onMouseDown={(e) => handleDragStart(v.id, v, e)}
                              onDoubleClick={() => onClickEditBle(v)}
                          >
                              <Icon
                                  as={FaBluetooth}
                                  height='20px'
                                  color='green.700'
                              />
                          </div>
                      ))}

                      {points.map((point, index) => (
                          <div
                              key={index}
                              style={{
                                  position: 'absolute',
                                  width: '10px',
                                  height: '10px',
                                  backgroundColor: 'black',
                                  borderRadius: '50%',
                                  top: `${point.y - 5}px`,
                                  left: `${point.x - 5}px`,
                                  pointerEvents: 'none',
                              }}
                          />
                      ))}

                      {points.length > 1 && points.map((point, index) => (
                          index > 0 && (
                              <svg
                                  key={`line-${index}`}
                                  style={{
                                      position: 'absolute',
                                      top: 0,
                                      left: 0,
                                      width: '100%',
                                      height: '100%',
                                      pointerEvents: 'none',
                                  }}
                              >
                                  <line
                                      x1={points[index - 1].x}
                                      y1={points[index - 1].y}
                                      x2={points[index].x}
                                      y2={points[index].y}
                                      stroke="black"
                                      strokeWidth="2"
                                  />
                              </svg>
                          )
                      ))}
                  </Box>
                </Box>
              )}
            </Flex>
            <Flex flex={1} bg="white" direction="column" alignItems="center" p={4} borderBottomRightRadius="30px">
              {!isShowAutocomplete ? null : (
                  <Flex direction="column" w="full">
                    <Text mb="10px" fontSize="16px" fontWeight="500" color="#292D32">{infoCompany?.name}</Text>
                    <FormControl>
                        <Select
                            variant='auth'
                            fontSize='sm'
                            fontWeight='500'
                            size='lg'
                            width="full"
                            value={JSON.stringify(infoAndar)}
                            placeholder="Selecione um andar"
                            onChange={(e) => {
                                if (e.target.value) {
                                    const getInfoAndar = JSON.parse(e.target.value);
                                    setInfoAndar(getInfoAndar);
                                    setPoints(JSON.parse(getInfoAndar?.points) || []);
                                }
                            }}
                        >
                            {list.map((v) => (
                                <option key={v.id} value={JSON.stringify(v)}>{v.name}</option>
                            ))}
                        </Select>
                    </FormControl>
                    <Flex alignItems="center" gap="20px" my="10px">
                      <Stack h="1px" w="full" bg="#9197B3" />
                      <Text color="#9197B3" fontSize="12px" fontWeight="500">OU</Text>
                      <Stack h="1px" w="full" bg="#9197B3" />
                    </Flex>
                    <Button
                      onClick={() => setIsOpen(true)}
                      w='full'
                      h='40px' 
                      variant='solid'
                      bg="main.700"
                      color="white"
                      borderRadius="8px"
                      fontWeight="500"
                      fontSize="12px"
                    >
                      Adicione um novo andar
                    </Button>
                  </Flex>
              )}
              {infoAndar ? (
                <>
                  <Flex gap="20px" mt="30px" width="full" direction="column">
                    <Text color="#9197B3" fontSize="12px" fontWeight="500">TRAJETO</Text>
                    <Button
                        onClick={toggleDrawing}
                        bg="#FAFAFA"
                        borderRadius="8px"
                        fontWeight="500"
                        color="#55DB30"
                        gap="10px"
                        justifyContent="flex-start"
                        minH="40px"
                        fontSize="12px"
                    >
                      <Flex
                        bg="#55DB30"
                        shadow="sm"
                        borderRadius="6px"
                        alignItems="center"
                        justifyContent="center"
                        display="flex"
                        minW="26px"
                        minH="26px"
                      >
                        {isDrawing ? <TbPlayerStop color="white" /> : <TbPlayerPlay color="white" />}
                      </Flex>
                      {isDrawing ? 'Parar' : 'Iniciar'}
                    </Button>
                    <Button
                        onClick={cleanRoute}
                        bg="#FAFAFA"
                        borderRadius="8px"
                        fontWeight="500"
                        color="#FF4640"
                        gap="10px"
                        justifyContent="flex-start"
                        minH="40px"
                        fontSize="12px"
                    >
                      <Flex
                        bg="#FF4640"
                        shadow="sm"
                        borderRadius="6px"
                        alignItems="center"
                        justifyContent="center"
                        display="flex"
                        minW="26px"
                        minH="26px"
                      >
                        <IconsClear />
                      </Flex>
                      Limpar
                    </Button>
                    <Button
                        onClick={updatePointsFloor}
                        variant='solid'
                        bg="main.700"
                        color="white"
                        borderRadius="8px"
                        fontWeight="500"
                        minH="40px"
                        fontSize="12px"
                    >
                        Salvar
                    </Button>
                  </Flex>
                  <Flex gap="20px" mt="30px" width="full" direction="column">
                    <Text color="#9197B3" fontSize="12px" fontWeight="500">BLE's</Text>
                    <Popover isOpen={isOpenBleInUse} onClose={() => setIsOpenBleInUse(false)}>
                        <PopoverTrigger>
                          <Button
                              onClick={() => setIsOpenBleInUse(true)}
                              bg="#FAFAFA"
                              borderRadius="8px"
                              fontWeight="500"
                              color="#55DB30"
                              gap="10px"
                              justifyContent="flex-start"
                              minH="40px"
                              border="1px"
                              borderColor="#9FA4BC"
                              fontSize="12px"
                          >
                            <Flex
                              bg="#55DB30"
                              shadow="sm"
                              borderRadius="6px"
                              alignItems="center"
                              justifyContent="center"
                              display="flex"
                              minW="26px"
                              minH="26px"
                            >
                              <Icon as={IoBluetooth} width='16px' height='16px' color="white" />
                            </Flex>
                            {`${listBle?.length || 0} ativos`}
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                            <PopoverCloseButton />
                            <PopoverHeader>BLE's em uso</PopoverHeader>
                            <PopoverBody maxH="250px" overflowY="auto">
                                <Input
                                    variant='auth'
                                    fontSize='sm'
                                    ms={{ base: "0px", md: "0px" }}
                                    placeholder='Buscar'
                                    fontWeight='500'
                                    mb="20px"
                                    size='lg'
                                    value={searchTxtBleInUse}
                                    onChange={(e) => setSearchTxtBleInUse(e.target.value)}
                                />
                                {listBleInUse.map((v) => (
                                    <Button
                                        onClick={() => {
                                            setIsOpenBleInUse(false);
                                            setIdBleInUseSelected(v.id);
                                            setTimeout(() => {
                                                setIdBleInUseSelected(null);
                                            }, 3000);
                                        }}
                                        w='100%'
                                        h='44px' 
                                        variant='solid'
                                        bg="green.700"
                                        color="white"
                                        key={v.id}
                                        mb="10px"
                                    >
                                        <Icon as={FaBluetooth} width='20px' height='20px' color='white' mr="5px" />
                                        {`BLE ${v.id}`}
                                    </Button>
                                ))}
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                    <Popover isOpen={isOpenBLE} onClose={() => setIsOpenBLE(false)}>
                        <PopoverTrigger>
                          <Button
                              onClick={() => setIsOpenBLE(true)}
                              bg="#FAFAFA"
                              borderRadius="8px"
                              fontWeight="500"
                              color="#FF4640"
                              gap="10px"
                              justifyContent="flex-start"
                              minH="40px"
                              border="1px"
                              borderColor="#9FA4BC"
                              fontSize="12px"
                          >
                            <Flex
                              bg="#FF4640"
                              shadow="sm"
                              borderRadius="6px"
                              alignItems="center"
                              justifyContent="center"
                              display="flex"
                              minW="26px"
                              minH="26px"
                            >
                              <Icon as={IoBluetooth} width='16px' height='16px' color="white" />
                            </Flex>
                            {`${listBleStock?.length || 0} inativos`}
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                            <PopoverCloseButton />
                            <PopoverHeader>BLE's em estoque</PopoverHeader>
                            <PopoverBody maxH="250px" overflowY="auto">
                                <Input
                                    variant='auth'
                                    fontSize='sm'
                                    ms={{ base: "0px", md: "0px" }}
                                    placeholder='Buscar'
                                    fontWeight='500'
                                    mb="20px"
                                    size='lg'
                                    value={searchTxtBle}
                                    onChange={(e) => setSearchTxtBle(e.target.value)}
                                />
                                {listBleStock.map((v) => (
                                    <Button
                                        onClick={() => {
                                            UpdateBLE(v, { x: 1, y: 1 });
                                            setIsOpenBLE(false);
                                        }}
                                        w='100%'
                                        h='44px' 
                                        variant='solid'
                                        bg="main.700"
                                        color="white"
                                        key={v.id}
                                        mb="10px"
                                    >
                                        <Icon as={FaBluetooth} width='20px' height='20px' color='white' mr="5px" />
                                        {`BLE ${v.id}`}
                                    </Button>
                                ))}
                            </PopoverBody>
                        </PopoverContent>
                    </Popover>
                  </Flex>
                </>
              ) : null}
            </Flex>
        </Flex>
        <ToastContainer />
        <Modal isOpen={isOpen} onClose={onClose} isCentered scrollBehavior="inside" size="lg">
            <ModalOverlay />
            <ModalContent fontFamily="Poppins">
                <ModalHeader textAlign="center" mt={5} color="#54595E" fontWeight="600" fontSize="20px">
                    {infoRow ? 'Editar andar' : 'Criar andar'}
                    <Text fontSize='14px' fontWeight='400' color="#54595E99">{`Preencha as informações abaixo para ${infoRow ? 'editar' : 'criar'} um andar`}</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody display="flex" flexDirection="column" alignItems="center">
                    <Formik
                        initialValues={{
                            name: infoRow?.name ? infoRow.name : '',
                            image: infoRow?.andar_image ? [infoRow.andar_image] : [],
                            periodStart: infoRow?.period_start ? infoRow.period_start.substring(0, 16) : '',
                            periodEnd: infoRow?.period_end ? infoRow.period_end.substring(0, 16) : '',
                        }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, helpers) => {
                            try {
                                const response = !infoRow?.id ? await CreateFloors(
                                    values.name,
                                    values.image[0],
                                    values.periodStart,
                                    values.periodEnd,
                                    infoCompany?.id,
                                    user?.token,
                                ) : await EditFloors(
                                    [],
                                    values.name,
                                    values.image[0],
                                    values.periodStart,
                                    values.periodEnd,
                                    infoCompany?.id,
                                    infoRow?.id,
                                    user?.token,
                                );
                                if (response?.status === 200 || response?.status === 201) {
                                    toast(response?.message || 'Andar salvo com sucesso!');
                                    helpers.setSubmitting(false);
                                    const responseUnique = await ListFloors(infoCompany?.id, infoRow?.id, '', 1, null, user?.token);
                                    const getInfoAndar = responseUnique?.result?.list ? responseUnique?.result?.list[0] : null;
                                    setInfoAndar(getInfoAndar);
                                    // setPoints(JSON.parse(getInfoAndar?.points) || []);
                                    onClose();
                                    getList();
                                } else {
                                    helpers.setStatus({ success: false });
                                    helpers.setErrors({ submit: response?.message });
                                    helpers.setSubmitting(false);
                                }
                            } catch (err) {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({ submit: err.message });
                                helpers.setSubmitting(false);
                            }
                        }}
                    >
                        {(props) => (
                            <Form style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <Field name='name'>
                                    {({ field, form }) => (
                                        <FormControl mb="20px" isInvalid={form.errors.name && form.touched.name}>
                                            {/* <FormLabel
                                                display='flex'
                                                ms='4px'
                                                fontSize='sm'
                                                fontWeight='500'
                                                color="main.700"
                                                mb='8px'
                                            >
                                                Nome do andar<Text color="main.700">*</Text>
                                            </FormLabel> */}
                                            <Input
                                                {...field}
                                                variant='auth'
                                                fontSize='sm'
                                                ms={{ base: "0px", md: "0px" }}
                                                placeholder='Nome do andar'
                                                fontWeight='500'
                                                size='lg'
                                                borderRadius="8px"
                                            />
                                            <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                <Grid templateColumns='repeat(2, 1fr)' gap='16px'>
                                    <Field name='periodStart'>
                                        {({ field, form }) => (
                                            <FormControl mb="20px" isInvalid={form.errors.periodStart && form.touched.periodStart}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Data inicial<Text color="main.700">*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='dd/MM/yyyy HH:mm'
                                                    fontWeight='500'
                                                    size='lg'
                                                    type="datetime-local"
                                                />
                                                <FormErrorMessage>{form.errors.periodStart}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='periodEnd'>
                                        {({ field, form }) => (
                                            <FormControl mb="20px" isInvalid={form.errors.periodEnd && form.touched.periodEnd}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Data final<Text color="main.700">*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='dd/MM/yyyy HH:mm'
                                                    fontWeight='500'
                                                    size='lg'
                                                    type="datetime-local"
                                                />
                                                <FormErrorMessage>{form.errors.periodEnd}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </Grid>
                                <Field name='image'>
                                    {({ form }) => (
                                        <FormControl isInvalid={form.errors.image && form.touched.image}>
                                            <Flex display="flex" flexDirection="column" maxW="100%">
                                                <Dropzone
                                                    minH="158px"
                                                    w="100%"
                                                    borderRadius="10px"
                                                    mt="24px"
                                                    onImageSelect={(file) => {
                                                        const uuid = v4();
                                                        setIdImage(uuid);
                                                        form.setFieldValue('image', file);
                                                    }}
                                                    accept="image/png, image/jpeg, image/jpg"
                                                    content={
                                                      <Box display="flex" flexDirection="column" gap="10px" alignItems="center" justifyContent="center">
                                                          {form?.values?.image?.length > 0 ? (
                                                              <Text color='blue.500' fontSize='xs' fontWeight='400'>{getNameWithExtensionFromUrl(String(form?.values?.image[0]), idImage)}</Text>
                                                          ) : (
                                                              <>
                                                                  <Gallery />
                                                                  <Text color='#ACACAC' fontSize='12px' fontWeight='400'>Selecione ou arraste uma imagem.</Text>
                                                                  <Text color='#ACACAC' fontSize='8px' fontWeight='400'>Formatos aceitos: PNG, JPEG, JPG</Text>
                                                              </>
                                                          )}
                                                      </Box>
                                                    }
                                                />
                                            </Flex>
                                            <FormErrorMessage>{form.errors.image}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                {props.errors.submit && (
                                    <Text
                                        color={props.status.success ? 'green.500' : 'red.500'}
                                        fontSize='small'
                                        fontWeight='400'
                                        marginTop="20px"
                                    >
                                        {props.errors.submit}
                                    </Text>
                                )}
                                <Button
                                    variant='solid'
                                    bg="main.700"
                                    color="white"
                                    isLoading={props.isSubmitting}
                                    type="submit"
                                    w="full"
                                    borderRadius="8px"
                                    fontWeight="500"
                                    marginTop="24px"
                                >
                                    {infoRow ? 'Editar andar' : 'Criar andar'}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>

                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal>
        <Modal isOpen={isOpenModalBle} onClose={onCloseBle} isCentered scrollBehavior="inside" size="lg">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    {infoRowBle ? 'Editar BLE' : 'Criar BLE'}
                    <Text color='secondaryGray.700' fontSize='xs' fontWeight='400'>{`Preencha as informações abaixo para ${infoRowBle ? 'editar' : 'criar'} um BLE`}</Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody display="flex" flexDirection="column" alignItems="center">
                    <Formik
                        initialValues={{
                            simpleId: infoRowBle?.simple_id ? infoRowBle.simple_id : '',
                            uuid: infoRowBle?.uuid ? infoRowBle.uuid : '',
                            macAddress: infoRowBle?.mac_address ? infoRowBle.mac_address : '',
                            positionX: infoRowBle?.position_x ? infoRowBle.position_x : '',
                            positionY: infoRowBle?.position_y ? infoRowBle.position_y : '',
                            enabled: infoRowBle?.enabled ? infoRowBle.enabled : false,
                        }}
                        validationSchema={validationSchemaBle}
                        onSubmit={async (values, helpers) => {
                            try {
                                const response = !infoRowBle?.id ? await CreateBLE(
                                    values.simpleId,
                                    values.uuid,
                                    values.macAddress,
                                    user?.token,
                                ) : await EditBLE(
                                    infoRowBle.id_andar,
                                    values.simpleId,
                                    values.uuid,
                                    values.macAddress,
                                    values.positionX,
                                    values.positionY,
                                    values.enabled,
                                    infoRowBle?.id,
                                    user?.token,
                                );
                                if (response?.status === 200 || response?.status === 201) {
                                    toast(response?.message || 'BLE salvo com sucesso!');
                                    helpers.setSubmitting(false);
                                    onCloseBle();
                                    getInfoMap();
                                } else {
                                    helpers.setStatus({ success: false });
                                    helpers.setErrors({ submit: response?.message });
                                    helpers.setSubmitting(false);
                                }
                            } catch (err) {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({ submit: err.message });
                                helpers.setSubmitting(false);
                            }
                        }}
                    >
                        {(props) => (
                            <Form style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <Grid templateColumns='repeat(2, 1fr)' gap='16px' position="block">
                                    <Field name='simpleId'>
                                        {({ field, form }) => (
                                            <FormControl mb="20px" isInvalid={form.errors.simpleId && form.touched.simpleId}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color="main.700"
                                                    mb='8px'
                                                >
                                                    SimpleID BLE<Text color="main.700">*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Digitar ID exemplo: 0000f0'
                                                    fontWeight='500'
                                                    size='lg'
                                                />
                                                <FormErrorMessage>{form.errors.simpleId}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field name='uuid'>
                                        {({ field, form }) => (
                                            <FormControl mb="20px" isInvalid={form.errors.uuid && form.touched.uuid}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color="main.700"
                                                    mb='8px'
                                                >
                                                    UUID<Text color="main.700">*</Text>
                                                </FormLabel>
                                                <Input
                                                    {...field}
                                                    variant='auth'
                                                    fontSize='sm'
                                                    ms={{ base: "0px", md: "0px" }}
                                                    placeholder='Digitar UUID'
                                                    fontWeight='500'
                                                    size='lg'
                                                />
                                                <FormErrorMessage>{form.errors.uuid}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </Grid>
                                <Field name='macAddress'>
                                    {({ field, form }) => (
                                        <FormControl mb="20px" isInvalid={form.errors.macAddress && form.touched.macAddress}>
                                            <FormLabel
                                                display='flex'
                                                ms='4px'
                                                fontSize='sm'
                                                fontWeight='500'
                                                color="main.700"
                                                mb='8px'
                                            >
                                                Mac address<Text color="main.700">*</Text>
                                            </FormLabel>
                                            <Input
                                                {...field}
                                                variant='auth'
                                                fontSize='sm'
                                                ms={{ base: "0px", md: "0px" }}
                                                placeholder='Digitar Mac Address'
                                                fontWeight='500'
                                                size='lg'
                                            />
                                            <FormErrorMessage>{form.errors.macAddress}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                {!infoRowBle?.id ? null : (
                                    <Grid templateColumns='repeat(2, 1fr)' gap='16px'>
                                        <Field name='positionX'>
                                            {({ field, form }) => (
                                                <FormControl mb="20px" isInvalid={form.errors.positionX && form.touched.positionX}>
                                                    <FormLabel
                                                        display='flex'
                                                        ms='4px'
                                                        fontSize='sm'
                                                        fontWeight='500'
                                                        color="main.700"
                                                        mb='8px'
                                                    >
                                                    Posição X<Text color="main.700">*</Text>
                                                    </FormLabel>
                                                    <Input
                                                        {...field}
                                                        variant='auth'
                                                        fontSize='sm'
                                                        ms={{ base: "0px", md: "0px" }}
                                                        placeholder='Digitar posição X'
                                                        fontWeight='500'
                                                        type="number"
                                                        size='lg'
                                                    />
                                                    <FormErrorMessage>{form.errors.positionX}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                        <Field name='positionY'>
                                            {({ field, form }) => (
                                                <FormControl mb="20px" isInvalid={form.errors.positionY && form.touched.positionY}>
                                                    <FormLabel
                                                        display='flex'
                                                        ms='4px'
                                                        fontSize='sm'
                                                        fontWeight='500'
                                                        color="main.700"
                                                        mb='8px'
                                                    >
                                                        Posição Y<Text color="main.700">*</Text>
                                                    </FormLabel>
                                                    <Input
                                                        {...field}
                                                        variant='auth'
                                                        fontSize='sm'
                                                        ms={{ base: "0px", md: "0px" }}
                                                        placeholder='Digitar posição Y'
                                                        fontWeight='500'
                                                        type="number"
                                                        size='lg'
                                                    />
                                                    <FormErrorMessage>{form.errors.positionY}</FormErrorMessage>
                                                </FormControl>
                                            )}
                                        </Field>
                                    </Grid>
                                )}
                                {!infoRowBle?.id ? null : (
                                    <Field name='enabled'>
                                        {({ field, form }) => (
                                            <FormControl isInvalid={form.errors.enabled && form.touched.enabled}>
                                                <FormLabel
                                                    display='flex'
                                                    ms='4px'
                                                    fontSize='sm'
                                                    fontWeight='500'
                                                    color={textColor}
                                                    mb='8px'
                                                >
                                                    Ativar BLE<Text color="main.700">*</Text>
                                                </FormLabel>
                                                <Switch
                                                    {...field}
                                                    size='lg'
                                                    colorScheme='brand'
                                                    isChecked={props.values.enabled}
                                                />
                                                <FormErrorMessage>{form.errors.enabled}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                )}
                                {props.errors.submit && (
                                    <Text
                                        color={props.status.success ? 'green.500' : 'red.500'}
                                        fontSize='small'
                                        fontWeight='400'
                                        marginTop="20px"
                                    >
                                        {props.errors.submit}
                                    </Text>
                                )}
                                <Flex justifyContent="space-between">
                                    <Button
                                        variant='solid'
                                        bg="red.600"
                                        color="white"
                                        w="150px"
                                        marginTop="24px"
                                        onClick={() => onClickDeleteBLE(infoRowBle)}
                                    >
                                        Remover BLE
                                    </Button>
                                    <Button
                                        variant='solid'
                                        bg="main.700"
                                        color="white"
                                        isLoading={props.isSubmitting}
                                        type="submit"
                                        alignSelf="flex-end"
                                        w="150px"
                                        marginTop="24px"
                                    >
                                        {infoRowBle ? 'Editar BLE' : 'Criar BLE'}
                                    </Button>
                                </Flex>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>

                <ModalFooter>
                </ModalFooter>
            </ModalContent>
        </Modal>
    </Card>
  );
}
