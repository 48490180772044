// chakra imports
import { Box, Flex, Stack, Text } from "@chakra-ui/react";
//   Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import { useAuth } from "hooks/use-auth";
// import SidebarCard from "components/sidebar/components/SidebarCard";
import React, { useCallback } from "react";
import { FiLogOut } from "react-icons/fi";

// FUNCTIONS

function SidebarContent(props) {
  const { routes } = props;
      const auth = useAuth();
  const handleSignOut = useCallback(
    () => {
      auth.signOut();
      window.location.replace('/#/auth/sign-in');
    },
    [auth]
  );
  // SIDEBAR
  return (
    <Flex direction='column' height='100%' pt='25px' px="16px" borderRadius='30px'>
      <Brand />
      <Stack direction='column' mb='auto' mt='8px'>
        <Box ps='20px' pe={{ md: "16px", "2xl": "1px" }}>
          <Links routes={routes} />
        </Box>
        <Flex onClick={handleSignOut} w="full" cursor="pointer" gap="10px" style={{ position: 'absolute', left: 50, bottom: 40 }}>
          <FiLogOut
            color="#FF3F3F"
            size={20}
            style={{ marginRight: 5 }}
          />
          <Text
            color="#FF3F3F"
            fontSize="14px"
            fontFamily="Poppins"
            fontWeight="500">
            Sair
          </Text>
        </Flex>
      </Stack>
      {/* <Box
        mt='60px'
        mb='40px'
        borderRadius='30px'>
        <SidebarCard />
      </Box> */}
    </Flex>
  );
}

export default SidebarContent;
