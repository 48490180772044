import React from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
//   Heading,
  Input,
  Text,
//   useColorModeValue,
} from "@chakra-ui/react";
// Custom components
// import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
// import { FcGoogle } from "react-icons/fc";
// import { useAuth } from 'hooks/use-auth';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { ReactComponent as Logo } from 'assets/img/logoAuth.svg';
import { Recover } from "services/auth";

function ForgotPassword() {
    // const auth = useAuth();

    // Chakra color mode
    // const textColor = useColorModeValue("navy.700", "white");
    // const textColorSecondary = "gray.400";
    // const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    // const textColorBrand = useColorModeValue("brand.500", "white");
    // const brandStars = useColorModeValue("brand.500", "brand.400");
    // const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
    // const googleText = useColorModeValue("navy.700", "white");
    // const googleHover = useColorModeValue(
    //     { bg: "gray.200" },
    //     { bg: "whiteAlpha.300" }
    // );
    // const googleActive = useColorModeValue(
    //     { bg: "secondaryGray.300" },
    //     { bg: "whiteAlpha.200" }
    // );

    const validationSchema = Yup.object().shape({
        email: Yup.string().email('E-mail inválido').required('E-mail é obrigatório'),
    });

    return (
        <DefaultAuth illustrationBackground={illustration} image={illustration}>
            <Flex
                maxW={{ base: "100%", md: "max-content" }}
                w='100%'
                mx={{ base: "auto", lg: "0px" }}
                me='auto'
                h='100%'
                alignItems='start'
                justifyContent='center'
                mb={{ base: "30px", md: "60px" }}
                px={{ base: "25px", md: "0px" }}
                mt={{ base: "40px", md: "14vh" }}
                flexDirection='column'
                fontFamily="Poppins"
            >
                <Box me='auto' w="full" display="flex" alignItems="center" justifyContent="center" mb="20px">
                    <Logo />
                    {/* <Heading color={textColor} fontSize='36px' mb='10px'>
                        Recuperar senha
                    </Heading>
                    <Text
                        mb='36px'
                        ms='4px'
                        color={textColorSecondary}
                        fontWeight='400'
                        fontSize='md'
                    >
                        Digite seu e-mail e enviaremos uma nova senha.
                    </Text> */}
                </Box>
                <Flex
                    zIndex='2'
                    direction='column'
                    w={{ base: "100%", md: "420px" }}
                    maxW='100%'
                    background='transparent'
                    borderRadius='15px'
                    mx={{ base: "auto", lg: "unset" }}
                    me='auto'
                    mb={{ base: "20px", md: "auto" }}
                >
                    <Formik
                        initialValues={{ email: '' }}
                        validationSchema={validationSchema}
                        onSubmit={async (values, helpers) => {
                            try {
                                const response = await Recover(
                                    values.email,
                                );
                                helpers.setStatus({ success: response?.status === 200 });
                                helpers.setErrors({ submit: response?.message });
                                helpers.setSubmitting(false);
                            } catch (err) {
                                helpers.setStatus({ success: false });
                                helpers.setErrors({ submit: err.message });
                                helpers.setSubmitting(false);
                            }
                        }}
                    >
                        {(props) => (
                            <Form>
                                <Field name='email'>
                                    {({ field, form }) => (
                                        <FormControl mb='24px' isInvalid={form.errors.email && form.touched.email}>
                                            <FormLabel
                                                display='flex'
                                                ms='4px'
                                                fontSize='sm'
                                                fontWeight='500'
                                                color="main.700"
                                                mb='8px'
                                            >
                                                E-mail<Text color="main.700">*</Text>
                                            </FormLabel>
                                            <Input
                                                {...field}
                                                variant='auth'
                                                fontSize='sm'
                                                ms={{ base: "0px", md: "0px" }}
                                                type='email'
                                                placeholder='Seu e-mail'
                                                fontWeight='500'
                                                size='lg'
                                                borderRadius="8px"
                                            />
                                            <FormErrorMessage mb='24px'>{form.errors.email}</FormErrorMessage>
                                        </FormControl>
                                    )}
                                </Field>
                                {props.errors.submit && (
                                    <Text
                                        color={props.status.success ? 'green.500' : 'red.500'}
                                        fontSize='small'
                                        fontWeight='400'
                                        marginTop="24px"
                                        mb="24px"
                                    >
                                        {props.errors.submit}
                                    </Text>
                                )}
                                <Button
                                    fontSize='sm'
                                    variant='solid'
                                    color="white"
                                    fontWeight='500'
                                    bg="main.700"
                                    w='100%'
                                    h='50'
                                    mb='24px'
                                    isLoading={props.isSubmitting}
                                    type="submit"
                                >
                                    Recuperar senha
                                </Button>
                            </Form>
                        )}
                    </Formik>
                    <Flex
                        flexDirection='column'
                        justifyContent='center'
                        alignItems='start'
                        maxW='100%'
                        mt='0px'
                    >
                        <Text color="#898F96" fontWeight='400' fontSize='14px'>
                            Lembrou da senha?
                            <NavLink to='/auth/sign-in'>
                                <Text
                                    color="main.700"
                                    as='span'
                                    ms='5px'
                                    fontWeight='700'
                                >
                                    Entrar
                                </Text>
                            </NavLink>
                        </Text>
                    </Flex>
                </Flex>
            </Flex>
        </DefaultAuth>
    );
}

export default ForgotPassword;
