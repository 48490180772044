import React from "react";
import { Button, Flex } from "@chakra-ui/react";

const Pagination = ({ currentPage, totalPages, onPageChange, limit }) => {
  const MAX_VISIBLE_PAGES = limit || 10;
  const pages = [];

  const startPage = Math.max(1, currentPage - Math.floor(MAX_VISIBLE_PAGES / 2));
  const endPage = Math.min(totalPages, startPage + MAX_VISIBLE_PAGES - 1);

  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  return (
    <Flex justify="center" align="center" mt={4}>
      <Button
        disabled={currentPage === 1}
        onClick={() => onPageChange(currentPage - 1)}
        mr={2}
        bg="#EEEEEE"
        variant="solid"
        borderRadius="4px"
        size="sm"
      >
        {'<'}
      </Button>
      {pages.map((page) => (
        <Button
          key={page}
          variant={currentPage === page ? "solid" : "outline"}
          bg={currentPage === page ? "#5932EA" : "#F5F5F5"}
          color={currentPage === page ? "white" : "#404B52"}
          onClick={() => onPageChange(page)}
          size="sm"
          mx={1}
          borderRadius="4px"
        >
          {page}
        </Button>
      ))}
      <Button
        disabled={currentPage === totalPages}
        onClick={() => onPageChange(currentPage + 1)}
        ml={2}
        bg="#EEEEEE"
        variant="solid"
        borderRadius="4px"
        size="sm"
      >
        {'>'}
      </Button>
    </Flex>
  );
};

export default Pagination;
